<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>拼词之星管理</el-breadcrumb-item>
        </el-breadcrumb>
                <!-- 个人赛赛务工具 -->
                <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">拼词之星工具</span>
                </el-col>
            </el-row>
            <el-row :gutter="30">
                <el-col
                    style="margin-top: 30px"
                    :span="4"
                    v-for="(item, i) of racingToolList"
                    v-bind:key="i"
                >
                    <gameToolBackView
                        :itemData="item"
                        :key="i"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      racingToolList: [
        {
          rightTitle: '房间管理',
          rightDesc: '查看/编辑比赛房间',
          icon: 'icon-diannao',
          menuTag: '1',
          flag: this.$chnEngStatusCode.pczx_room_management,
          turnUrl: '/pczx_room_manage'
        },
        {
          rightTitle: '签到管理',
          rightDesc: '查看/编辑签到信息',
          icon: 'icon-shijuan',
          menuTag: '2',
          flag: this.$chnEngStatusCode.pczx_signin_management,
          turnUrl: '/pczx_signin_manage'
        }
      ]
    }
  },
  methods: {
    racingToolClicked (itemData) {
      console.log('itemData', itemData)
      this.$router.push(
        {
          path: itemData.turnUrl
        }
      )
    }
  }
}
</script>

<style>

</style>
